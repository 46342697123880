
import { defineComponent, reactive, ref } from "vue";
import { inject } from "vue";
import { Services } from "@/core/models/metadata/services.ts";
import RemoteOriginatingSiteService from "@/core/services/remoteOriginatingSiteService.ts";
import { RemoteOriginatingSiteResponse } from "@/core/models/responses/remoteOriginatingSiteResponse.ts";
import { useAuth0 } from "@auth0/auth0-vue";
import LeftPanel from './leftPanel.vue'

let remoteOriginatingSiteService!: RemoteOriginatingSiteService;
let buttonInfo: RemoteOriginatingSiteResponse = {
  isSuccess: false,
  FailureMessage: "",
  title: "",
  url: "",
};
export default defineComponent({
  name: "UserProfile",
  mounted() {
    remoteOriginatingSiteService = inject(
      Services.RemoteOriginatingSiteService
    ) as RemoteOriginatingSiteService;
    remoteOriginatingSiteService.retrieveUrlAndTitle()
    .then((response) => {
      this.buttonInfo = response;
      this.buttonInfoLoading = false;
    });
  },
  data() {
    const auth0 = reactive(useAuth0());
    return {
      buttonInfo: buttonInfo,
      buttonInfoLoading: true,
      reactiveClient: ref(auth0)
    };
  },
  computed:{
    isAuthenticated(): boolean | undefined { return this.reactiveClient?.isAuthenticated }
  },
  components: {
    LeftPanel
  },
});
