import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ca53704c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "aside aside-right" }
const _hoisted_3 = { class: "componentTitle" }
const _hoisted_4 = { class: "cardMainContent" }
const _hoisted_5 = { class: "card-text" }
const _hoisted_6 = { class: "buttons-container" }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeftPanel = _resolveComponent("LeftPanel")!
  const _component_RadialSpinner = _resolveComponent("RadialSpinner")!
  const _component_BlockableButton = _resolveComponent("BlockableButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isAuthenticated)
      ? (_openBlock(), _createBlock(_component_LeftPanel, {
          key: 0,
          buttonInfo: _ctx.buttonInfo,
          buttonInfoLoading: _ctx.buttonInfoLoading,
          isAuthenticated: _ctx.isAuthenticated
        }, null, 8, ["buttonInfo", "buttonInfoLoading", "isAuthenticated"]))
      : _createCommentVNode("", true),
    _createElementVNode("aside", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.thankyouForMessage), 1),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.contact24h), 1),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.buttonInfoLoading && !_ctx.isAuthenticated)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_RadialSpinner)
                ]))
              : _createCommentVNode("", true),
            (_ctx.buttonInfo.isSuccess && !_ctx.buttonInfoLoading && !_ctx.isAuthenticated)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.redirectToOriginatingSite && _ctx.redirectToOriginatingSite(...args))),
                  class: "return-btn"
                }, _toDisplayString(_ctx.goTo) + _toDisplayString(_ctx.buttonInfo.title), 1))
              : _createCommentVNode("", true),
            (_ctx.isAuthenticated)
              ? (_openBlock(), _createBlock(_component_BlockableButton, {
                  key: 2,
                  route: "/userProfile",
                  class: "whiteBgButton",
                  buttonText: _ctx.backToDetails
                }, null, 8, ["buttonText"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}