<template>
    <div class="rsc-onetrust-cookie-policy">
        <div class="rsc-onetrust-cookie-settings">
            <!-- OneTrust Cookies Settings button start -->
            <button id="ot-sdk-btn" class="btn btn--std ot-sdk-show-settings">Cookie Settings</button>
            <!-- OneTrust Cookies Settings button end -->
        </div>
        <!-- OneTrust Cookies List start -->
        <div id="ot-sdk-cookie-policy"></div>
        <!-- OneTrust Cookies List end -->
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped>
.rsc-onetrust-cookie-settings {
    text-align: right
}
</style>