import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5831908e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "separator-line" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_menu, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_list, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
              return (_openBlock(), _createBlock(_component_v_list_item, {
                key: index,
                value: index,
                onClick: ($event: any) => (_ctx.handleItemClick(item.title))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createElementVNode("span", null, [
                          _createElementVNode("button", {
                            class: _normalizeClass(item.class ?? 'nav-open-btn'),
                            onClick: item.clickHandler
                          }, [
                            _createElementVNode("img", {
                              src: item.iconSvg,
                              alt: item.alt
                            }, null, 8, _hoisted_3),
                            _createTextVNode(" " + _toDisplayString(item.title), 1)
                          ], 10, _hoisted_2)
                        ])
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["value", "onClick"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}