<template>
    <div class="spin-container">
        <div class="spin">
        </div>
    </div>
</template>
<style>
    .spin-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    @keyframes spinner {
        0% {
            transform: translate3d(-50%, -50%, 0) rotate(0deg);
        }

        100% {
            transform: translate3d(-50%, -50%, 0) rotate(360deg);
        }
    }

    .spin::before {
        animation: 1.5s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite spinner;
        animation-play-state: inherit;
        border: solid 5px #cfd0d1;
        border-bottom-color: #303030 !important;
        border-radius: 50%;
        content: "";
        height: 40px;
        width: 40px;
        position: relative;
        display: flex;
        margin-left: 50%;
        will-change: transform;
    }

    .spin {
        text-align: center;
        bottom: 0;
    }
</style>
<script>
    export default {
        name: 'RadialSpinner'
    }
</script>