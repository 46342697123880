import { Auth0Plugin } from "@auth0/auth0-vue";

import { BackendServiceBase } from "./backendServiceBase.ts";
import { ResendEmailVerificationResponse } from "../models/responses/resendEmailVerificationResponse.ts";
import { ErrorsService } from "./errorsService.ts";
import Resources from "../models/resources.ts";
import { BlockNavigationService } from "./blockNavigationService.ts";
import { ErrorType } from "../models/metadata/error.ts";

export class EmailVerificationService extends BackendServiceBase<any, ResendEmailVerificationResponse>{
    
    constructor(private readonly resources: Resources, blockNavigationService: BlockNavigationService, errorsService: ErrorsService, auth0Plugin: Auth0Plugin, backendEndpoint: string) {
        super(blockNavigationService, errorsService, auth0Plugin, backendEndpoint, ErrorType.ResendEmailVerification);
    }

    protected override defineErrorMessage(): string {
        return this.resources.errors.resendEmailVerification;
    }
    protected buildFetch(headers: any, _?: any): Promise<Response> {
        return fetch(`${this.backendEndpoint}/resendEmailVerification`, {
            headers: headers,
            method: 'POST'
        });
    }
    
}