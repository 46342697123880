
import { Services } from "@/core/models/metadata/services.ts";
import { auth0UserMetadataLoaded } from "@/core/common/functions.ts";
import Resources from "@/core/models/resources.ts";
import { defineComponent, inject } from "vue";
import { Auth0VueClient, useAuth0 } from "@auth0/auth0-vue";
import { Auth0IdTokenClaimsKeys } from "@/core/models/metadata/auth0IdTokenClaimsKeys.ts";

let resources: Resources;

let auth0Client!: Auth0VueClient;
export default defineComponent({
  name: "DashboardComponent",
  async created() {
    await auth0UserMetadataLoaded(auth0Client);
    this.userTitle = auth0Client.user?.value[Auth0IdTokenClaimsKeys.UserMetadata]?.title ?? "";
  },
  data() {
    auth0Client = useAuth0();
    resources = inject(Services.Resources) as Resources;
    return {
      userTitle: auth0Client.user?.value[Auth0IdTokenClaimsKeys.UserMetadata]?.title ?? "",
      userFirstName: auth0Client.user?.value?.given_name ?? "",
      userLastName: auth0Client.user?.value?.family_name ?? "",
      lastUserEmail: auth0Client.user.value.email,
      linkToChangeName: resources.labels.name,
      linkToChangeEmail: resources.labels.emailAddress,
      linkToChangePassword: resources.labels.password,
      title: resources.titles.keyDetails,
      editWelcome: resources.messages.editWelcome,
      name: resources.labels.name,
      windowWidth: window.innerWidth,
      totalPercentage:null
    };
  },
 



});

