import { Auth0Plugin } from "@auth0/auth0-vue";
import { ErrorsService } from "./errorsService.ts";
import Resources from "../models/resources.ts";
import { ErrorType } from "../models/metadata/error.ts";

export class PasswordlessEmailCodeService {
    private temporalUserId: string = '';
    constructor(private readonly auth0Client: Auth0Plugin,
        private readonly errorService: ErrorsService,
        private readonly resources: Resources) {

    }

    private headers: HeadersInit = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    };

    //https://auth0.com/docs/api/authentication#get-code-or-link
    private passwordlessStartBodyBuilder = (email: string) => JSON.stringify({
        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
        connection: 'email',
        email: email,
        send: 'code'
    });
    public async trySendPasswordlessStart(email: string): Promise<boolean> {
        if (!this.auth0Client.isAuthenticated.value)
            return false;
        this.errorService.deleteByType(ErrorType.OtpCode);
        try {
            const response = await fetch(`https://${process.env.VUE_APP_AUTH0_SERVER_URI}/passwordless/start`,
                {
                    method: 'POST',
                    headers: this.headers,
                    body: this.passwordlessStartBodyBuilder(email)
                });     
            const responseAsObject = await response.json();
            this.temporalUserId = responseAsObject._id;
            return response.status === 200;
        } catch (e){
            console.log(e)
            this.errorService.push(this.resources.errors.updateEmail, ErrorType.OtpCode);
            return false;
        }
    }

    //https://auth0.com/docs/api/authentication#authenticate-user
    private otpAuthBodyBuilder = (code: string, email: string) => JSON.stringify({
        grant_type: 'http://auth0.com/oauth/grant-type/passwordless/otp',
        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
        realm: 'email',
        username: email,
        otp: code,
    });
    //We cannot use it anymore as this app is registered as a regular_web_application, and it's needed that client_secret is provided at the request body
    public async tryAuthenticateWithOtpCode(code: string, email: string): Promise<'ok' | 'expired' | 'server-error' | 'not-authenticated'> {
        if (!this.auth0Client.isAuthenticated.value)
            return 'not-authenticated';
        this.errorService.deleteByType(ErrorType.OtpCode);
        try {
            const response = await fetch(`https://${process.env.VUE_APP_AUTH0_SERVER_URI}/oauth/token`,
                {
                    method: 'POST',
                    headers: this.headers,
                    body: this.otpAuthBodyBuilder(code, email)
                });
            
            return response.status === 200 ? 'ok' : response.status === 403 ? 'expired' : 'server-error';
        } catch {
            this.errorService.push(this.resources.errors.updateEmail, ErrorType.OtpCode);
            return 'server-error';
        }
    }

    public getTemporalUserId(): string{
        return this.temporalUserId;
    }
}