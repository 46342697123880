import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-605d2020"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "aside aside-right" }
const _hoisted_3 = { class: "componentTitle" }
const _hoisted_4 = { class: "cardMainContent" }
const _hoisted_5 = { class: "card-text" }
const _hoisted_6 = { class: "card-text-email" }
const _hoisted_7 = { class: "card-text" }
const _hoisted_8 = { class: "card-text-note" }
const _hoisted_9 = { class: "buttons-wrapper" }
const _hoisted_10 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeftPanel = _resolveComponent("LeftPanel")!
  const _component_ErrorsManager = _resolveComponent("ErrorsManager")!
  const _component_RadialSpinner = _resolveComponent("RadialSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LeftPanel, {
      buttonInfo: _ctx.buttonInfo,
      buttonInfoLoading: _ctx.buttonInfoLoading,
      isAuthenticated: _ctx.isAuthenticated
    }, null, 8, ["buttonInfo", "buttonInfoLoading", "isAuthenticated"]),
    _createElementVNode("aside", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.emailTitle), 1),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.validationEmailSent), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.newUserEmail), 1),
          _createElementVNode("p", _hoisted_7, [
            _createElementVNode("b", null, _toDisplayString(_ctx.clickLinkToEmailChange), 1)
          ]),
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.emailActiveUntilDone), 1),
          _createVNode(_component_ErrorsManager, { errorType: _ctx.errorType }, null, 8, ["errorType"]),
          _createElementVNode("div", _hoisted_9, [
            (_ctx.isLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_RadialSpinner)
                ]))
              : (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: "blueBgButton",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.sendEmailVerificationRequest && _ctx.sendEmailVerificationRequest(...args)))
                }, _toDisplayString(_ctx.emailText), 1))
          ])
        ])
      ])
    ])
  ]))
}