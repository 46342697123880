import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61b2ebd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "aside aside-left" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["title"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["title"]
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadialSpinner = _resolveComponent("RadialSpinner")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    (_ctx.buttonInfoLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_RadialSpinner)
        ]))
      : _createCommentVNode("", true),
    (_ctx.buttonInfo.isSuccess && !_ctx.buttonInfoLoading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass({ ..._ctx.cardData.borderClasses, 'button-parent': true })
        }, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.redirectToOriginatingSite && _ctx.redirectToOriginatingSite(...args))),
            class: "return-btn"
          }, [
            _createElementVNode("img", {
              src: _ctx.cardData.plusIcon,
              alt: "Return icon",
              class: "card-link-icon"
            }, null, 8, _hoisted_3),
            _createElementVNode("span", {
              class: _normalizeClass({ ..._ctx.cardData.linksTextClasses, 'text-color-blue': true, 'marginLeft': true })
            }, _toDisplayString(_ctx.goTo) + _toDisplayString(_ctx.buttonInfo.title), 3)
          ])
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.isAuthenticated)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "cardManageKeyDetails",
          title: _ctx.cardData.linksTitles
        }, [
          _createVNode(_component_router_link, {
            to: "/userProfile/",
            class: _normalizeClass(_ctx.cardData.linksTextClasses)
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: _ctx.cardData.userIcon,
                alt: "User profile icon",
                class: "router-link-icon"
              }, null, 8, _hoisted_5),
              _createElementVNode("span", {
                class: _normalizeClass({ ..._ctx.cardData.linksTextClasses, 'fontWeight400': true, 'science-blue-100': true})
              }, _toDisplayString(_ctx.manageKeyDetails), 3)
            ]),
            _: 1
          }, 8, ["class"])
        ], 8, _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.showContactFormLink)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "card",
          title: _ctx.cardData.linksTitles
        }, [
          _createElementVNode("h2", {
            class: _normalizeClass(_ctx.cardData.titlesClasses)
          }, _toDisplayString(_ctx.needHelp), 3),
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.cardData.textClasses)
          }, _toDisplayString(_ctx.problemContactForm), 3),
          _createVNode(_component_router_link, {
            to: "/contactForm",
            class: _normalizeClass({ ..._ctx.cardData.linksTextClasses, 'contact-form-link': true })
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", {
                class: _normalizeClass({ ..._ctx.cardData.linksTextClasses, 'science-blue-100': true})
              }, _toDisplayString(_ctx.contactCS), 3),
              _createElementVNode("img", {
                src: _ctx.cardData.arrowIcon,
                alt: "Return icon",
                class: "card-link-icon"
              }, null, 8, _hoisted_7)
            ]),
            _: 1
          }, 8, ["class"])
        ], 8, _hoisted_6))
      : _createCommentVNode("", true)
  ]))
}