import { Router } from "vue-router";
import { IsValidOriginQuery } from "../models/queries/isValidOriginQuery.ts";
import { BaseResponse } from "../models/responses/baseResponse.ts";
import { RemoteOriginatingSiteResponse } from "../models/responses/remoteOriginatingSiteResponse.ts";
import { IsValidEndpointService } from "./isValidEndpointService.ts";

interface IRemoteOriginatingSiteService {
    saveUrlAtLocalStorage: () => Promise<void>
    getIntendedAccessPath: () => Promise<string>
    retrieveUrlAndTitle: () => Promise<RemoteOriginatingSiteResponse>
    redirectToSelfWithReturnUrl: () => Promise<void>
}
export default class RemoteOriginatingSiteService implements IRemoteOriginatingSiteService {
    private readonly urlLocalStorageKey: string = 'origin-url';
    private readonly returnUrlQueryParamName: string = 'returnUrl';
    private readonly intendedAccessPathQueryParamName: string = 'intendedAccessPath';
    private remoteOriginatingSiteResponse: RemoteOriginatingSiteResponse = {
        isSuccess: false,
        FailureMessage: '',
        title: '',
        url: ''
    };

    constructor(
        private readonly isValidEndpointService: IsValidEndpointService,
        private readonly router: Router) {

    }
    public getIntendedAccessPath = (): Promise<string> => {
        return new Promise((resolve, reject) => {
            if(!window.location.search.length)
                return resolve('');

            const query = window.location.search.substring(1);
            const params = new URLSearchParams(query);
            const queryWithPathOrEmpty = window.location.search ? `/${window.location.search}` : '';
            const intendedAccessPath = decodeURIComponent(params.get(this.intendedAccessPathQueryParamName) ?? queryWithPathOrEmpty);
            resolve(intendedAccessPath);
        });
    }
    public redirectToSelfWithReturnUrl = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            let route = `/?${this.intendedAccessPathQueryParamName}=${this.router.currentRoute.value.path}`;

            if(this.remoteOriginatingSiteResponse.url)
                route = `${route}&${this.returnUrlQueryParamName}=${encodeURIComponent(this.remoteOriginatingSiteResponse.url)}`;
            this.router.push(route)
            resolve();
        });
    };
    public saveUrlAtLocalStorage = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            
            localStorage.setItem(this.urlLocalStorageKey, window.document.referrer);
            resolve();
        })
    }
    public retrieveUrlAndTitle = (): Promise<RemoteOriginatingSiteResponse> => {
        return new Promise((resolve, reject) => {
            try {
                if(this.remoteOriginatingSiteResponse.isSuccess)
                    return resolve(this.remoteOriginatingSiteResponse);
                    
                const valueStored = localStorage.getItem(this.urlLocalStorageKey);
                if (!valueStored)
                    return resolve({
                        isSuccess: false
                    } as RemoteOriginatingSiteResponse);

                localStorage.removeItem(this.urlLocalStorageKey);
                 
                this.isValidEndpointService.handle({ origin: valueStored } as IsValidOriginQuery)
                .then((responseFromServer: RemoteOriginatingSiteResponse | BaseResponse) => {
                    this.remoteOriginatingSiteResponse = responseFromServer as RemoteOriginatingSiteResponse;
                    this.remoteOriginatingSiteResponse.url = decodeURI(valueStored);
                    resolve(this.remoteOriginatingSiteResponse);
                })
            }catch(e){
                console.log(e);
                return reject(e);
            }

        });
    }
    
    public retrieveUrlAndTitleFromNotDefault = (): Promise<RemoteOriginatingSiteResponse> => {
        return new Promise((resolve, reject) => {
            const isNavigationFromThisSite = window.document.referrer.startsWith(`${window.location.protocol}//${window.location.hostname}`);
            if(this.remoteOriginatingSiteResponse.isSuccess || isNavigationFromThisSite)
                return resolve(this.remoteOriginatingSiteResponse);
            
                this.isValidEndpointService.handle({ origin: window.document.referrer } as IsValidOriginQuery)
                .then((responseFromServer: RemoteOriginatingSiteResponse | BaseResponse) => {
                    this.remoteOriginatingSiteResponse = responseFromServer as RemoteOriginatingSiteResponse;
                    this.remoteOriginatingSiteResponse.url = decodeURI(window.document.referrer);
                    resolve(this.remoteOriginatingSiteResponse);
                })

        });
    }
}
