
import { defineComponent, inject } from "vue";
import { Services } from "@/core/models/metadata/services.ts";
import Resources from "@/core/models/resources.ts";
import BlockableButton from "../../shared/blockableButton.vue";

let resources!: Resources;
export default defineComponent({
  name: "PersonalInfoUpdated",
  data() {
    resources = inject(Services.Resources) as Resources;
    return {
      backToDetails: resources.links.backToDetails,
      changeYourName: resources.titles.changeYourName,
      nameUpdated: resources.messages.nameUpdated,
      shortWhile: resources.messages.shortWhile,
    };
  },
  components: {
    BlockableButton,
  },
});
