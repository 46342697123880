import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ef782d70"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", { title: _ctx.linksTitles }, [
    _createVNode(_component_router_link, {
      id: _ctx.idTag,
      to: _ctx.route,
      class: _normalizeClass(_ctx.getRouterLinkClasses)
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
      ]),
      _: 1
    }, 8, ["id", "to", "class"])
  ], 8, _hoisted_1))
}