export enum Services {
  Resources = 'resourcesService',
  UpdateUser = 'updateUserService',
  UpdateUserNames = 'updateUserNamesService',
  EmailVerification = 'emailVerificationService',
  ValidationRules = 'validationRulesService',
  PrivateContactForm = 'contactFormService',
  PublicContactForm = 'publicContactFormService',
  IsValidEndpointService = 'isValidEndpointResponse',
  RemoteOriginatingSiteService = 'remoteOriginatingSiteService',
  ErrorsService = 'errorsService',
  BlockNavigationService = 'blockNavigationService',
  PasswordlessEmailCode = "PasswordlessEmailCode",
  UpdateUserMetadataNewEmail = "UpdateUserMetadataNewEmail",
  StringHelper = "StringHelper",
  StopOtpCode = "StopOtpCode",
  LoginWithOtpCode = "LoginWithOtpCode",
  UserExists = "UserExists"
}