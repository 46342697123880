
import { defineComponent, inject } from "vue";
import { Auth0VueClient, useAuth0 } from "@auth0/auth0-vue";
import { Services } from "@/core/models/metadata/services.ts";
import Resources from "@/core/models/resources.ts";
import RemoteOriginatingSiteService from "@/core/services/remoteOriginatingSiteService.ts";
import { RemoteOriginatingSiteResponse } from "@/core/models/responses/remoteOriginatingSiteResponse.ts";
import LeftPanel from '../leftPanel.vue'

let auth0!: Auth0VueClient;
let resources!: Resources;
let remoteOriginatingSiteService!: RemoteOriginatingSiteService;
let buttonInfo: RemoteOriginatingSiteResponse = {
  isSuccess: false,
  FailureMessage: "",
  title: "",
  url: "",
};
export default defineComponent({
  name: "PasswordUpdated",
  mounted() {
    remoteOriginatingSiteService = inject(
      Services.RemoteOriginatingSiteService
    ) as RemoteOriginatingSiteService;
    remoteOriginatingSiteService
      .retrieveUrlAndTitleFromNotDefault()
      .then((response: RemoteOriginatingSiteResponse) => {
        this.buttonInfo = response;
        this.buttonInfoLoading = false;
      });
  },
  data() {
    resources = inject(Services.Resources) as Resources;
    auth0 = useAuth0();
    return {
      buttonInfo,
      buttonInfoLoading: true,
      title: resources.titles.changePassword,
      passwordUpdated: resources.messages.passwordUpdated,
      signInWithNewPassword: resources.links.signInWithNewPassword,
    };
  },
  methods: {
    logIn() {
      auth0.loginWithRedirect({
        authorizationParams: {
          redirect_uri: process.env.VUE_APP_AUTH0_REDIRECT_URI
        }
      });
    },
  },
  components:{
    LeftPanel
  }
});
