import { Auth0Plugin } from "@auth0/auth0-vue";

export class JwtsWrapper {
    private static isJwtRotted: boolean;
    private static hasSessionCheckStarted: boolean;
    private static intervalId: number;
    static getIntervalId(): number{
        return this.intervalId;
    }
    static getHasSessionCheckStarted(): boolean{
        return this.hasSessionCheckStarted;
    }
    static async getJwt(plugin: Auth0Plugin): Promise<string> {
        if (!this.isJwtRotted)
            return plugin.idTokenClaims.value.__raw;

        await this.checkSession(plugin);

        this.isJwtRotted = false;
        return plugin.idTokenClaims.value.__raw;
    }

    static setJwtAsRotted() {
        this.isJwtRotted = true;
    }

    static async launchCheckSession(client: Auth0Plugin): Promise<void>{
        if(this.hasSessionCheckStarted || !client.isAuthenticated.value)
            return;
        
        this.hasSessionCheckStarted = true;

        const checkSession = this.checkSession;

        await checkSession(client);
        
        const timeBetweenChecks = client.idTokenClaims.value.exp!*1000 - Date.now();
        this.intervalId = setInterval(async () => {
            await checkSession(client, () => clearInterval(JwtsWrapper.getIntervalId()))
        }, timeBetweenChecks > 0 ? timeBetweenChecks : 5*60*1000)
        
    }
    public static async checkSession(client: Auth0Plugin, onError?: () => void){
        try{
            await client.getAccessTokenSilently({
                cacheMode: 'off'
            });
        }catch(error){
            onError ? onError() : undefined;
            await client.logout();            
        }
    }
    public static async delayFromSeconds(amount: number): Promise<void>{
        return new Promise(res => setTimeout(res, amount*1000))
    }
}