import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f7ac7d3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "changeNameTitle",
  class: "componentTitle"
}
const _hoisted_2 = { class: "cardMainContent" }
const _hoisted_3 = {
  id: "changeTitleLabel",
  class: "label"
}
const _hoisted_4 = {
  id: "changeFirstNamelabel",
  class: "label"
}
const _hoisted_5 = {
  id: "changeLastNameLabel",
  class: "label"
}
const _hoisted_6 = { class: "buttonsBox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_ErrorsManager = _resolveComponent("ErrorsManager")!
  const _component_BlockableButton = _resolveComponent("BlockableButton")!
  const _component_RadialSpinner = _resolveComponent("RadialSpinner")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.changeYourName), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_form, {
        "validate-on": "submit",
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"])),
        modelValue: _ctx.isValid,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isValid) = $event))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.enterTitleLabel), 1),
            _createVNode(_component_v_select, _mergeProps({
              id: _ctx.changeTitle,
              modelValue: _ctx.newUserTitle,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newUserTitle) = $event)),
              items: _ctx.titles
            }, _ctx.vProps), null, 16, ["id", "modelValue", "items"])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.enterNewFirstNameLabel), 1),
            _createVNode(_component_v_text_field, _mergeProps({
              id: "changeFirstName",
              class: "input"
            }, _ctx.vProps, {
              modelValue: _ctx.newUserFirstName,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newUserFirstName) = $event)),
              rules: _ctx.rules.firstName,
              "validate-on": "blur"
            }), null, 16, ["modelValue", "rules"])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.enterNewLastNameLabel), 1),
            _createVNode(_component_v_text_field, _mergeProps({
              id: "changeLastName",
              class: "input"
            }, _ctx.vProps, {
              modelValue: _ctx.newUserLastName,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newUserLastName) = $event)),
              rules: _ctx.rules.lastName,
              "validate-on": "blur"
            }), null, 16, ["modelValue", "rules"])
          ]),
          _createVNode(_component_ErrorsManager, {
            id: "errors",
            errorType: _ctx.errorType
          }, null, 8, ["errorType"]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_BlockableButton, {
              idTag: "changeNameBack",
              route: "/userProfile",
              buttonText: _ctx.buttonBack,
              underlined: ""
            }, null, 8, ["buttonText"]),
            (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_RadialSpinner, { key: 0 }))
              : (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  id: "changeNameSave",
                  type: "submit",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args))),
                  class: "blueBgButton"
                }, _toDisplayString(_ctx.buttonSave), 1))
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ], 64))
}