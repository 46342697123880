import { Auth0Plugin } from "@auth0/auth0-vue";
import { ErrorType } from "../models/metadata/error.ts";
import Resources from "../models/resources.ts";
import { StopOtpResponse } from "../models/responses/stopOtpResponse.ts";
import { BackendServiceBase } from "./backendServiceBase.ts";
import { BlockNavigationService } from "./blockNavigationService.ts";
import { ErrorsService } from "./errorsService.ts";

export class StopOtpService extends BackendServiceBase<any, StopOtpResponse>{

    constructor(private readonly resources: Resources, blockNavigationService: BlockNavigationService, errorsService: ErrorsService, auth0Plugin: Auth0Plugin, backendEndpoint: string) {
        super(blockNavigationService, errorsService, auth0Plugin, backendEndpoint, ErrorType.StopOtpCode);
    }

    protected override defineErrorMessage(): string {
        return this.resources.errors.otpCode;
    }
    protected buildFetch(headers: any, query?: any | undefined): Promise<Response> {
        return fetch(`${this.backendEndpoint}/stopOtpCodeProcess`,{
            headers: headers,
            method: 'GET'
        });
    }

}