
import { defineComponent, inject } from "vue";
import { Services } from "@/core/models/metadata/services.ts";
import Resources from "@/core/models/resources.ts";
import ErrorsManager from "../../shared/errorsManager.vue";
import RadialSpinner from "../../shared/radialSpinner.vue";
import { ValidationRulesService } from "@/core/services/validationRulesService.ts";
import { ErrorType } from "@/core/models/metadata/error.ts";
import { PasswordlessEmailCodeService } from "@/core/services/passwordlessEmailCodeService.ts";
import { UpdateUserService } from "@/core/services/updateUserService.ts";
import { Auth0VueClient, useAuth0 } from "@auth0/auth0-vue";
import { UpdateUserQuery } from "@/core/models/queries/updateUserQuery.ts";
import { fieldType } from "@/core/models/queries/fieldTypeEnum.ts";
import { Auth0IdTokenClaimsKeys } from "@/core/models/metadata/auth0IdTokenClaimsKeys.ts";
import { StringHelper } from "@/core/services/stringHelper.ts";
import { UpdateuserMetadataNewEmailQuery } from "@/core/models/queries/updateUserMetadataNewEmailQuery.ts";
import { UpdateUserMetadataNewEmailService } from "@/core/services/updateUserMetadataNewEmailService.ts";
import { OtpLoginService } from "@/core/services/otpLoginService.ts";
import { tryManageOtpStart } from "@/core/common/functions.ts";
import { OtpLoginResponse } from "@/core/models/responses/otpLoginResponse.ts";
import { BlockNavigationService } from "@/core/services/blockNavigationService.ts";
import { StopOtpService } from "@/core/services/stopOtpService.ts";

let passwordlessEmailCodeService!: PasswordlessEmailCodeService;
let updateUserService!: UpdateUserService;
let updateUserMetadataService!: UpdateUserMetadataNewEmailService;
let auth0Client!: Auth0VueClient;
let otpCodeLoginService!: OtpLoginService;
let blockNavigationService!: BlockNavigationService;
let stopOtpService!: StopOtpService;
export default defineComponent({
    name: "UpdateEmailOtpSent",
    data() {
        const resources = inject(Services.Resources) as Resources;
        const validationRulesService = inject(Services.ValidationRules) as ValidationRulesService;
        passwordlessEmailCodeService = inject(Services.PasswordlessEmailCode) as PasswordlessEmailCodeService;
        auth0Client = useAuth0();
        updateUserService = inject(Services.UpdateUser) as UpdateUserService;
        updateUserMetadataService = inject(Services.UpdateUserMetadataNewEmail) as UpdateUserMetadataNewEmailService;
        otpCodeLoginService = inject(Services.LoginWithOtpCode) as OtpLoginService;
        const stringHelper = inject(Services.StringHelper) as StringHelper;
        blockNavigationService = inject(Services.BlockNavigationService)!;
        stopOtpService = inject(Services.StopOtpCode) as StopOtpService;
        const newEmail = auth0Client.user.value[Auth0IdTokenClaimsKeys.UserMetadata]?.newEmail;
        return {
            checkYourEmail: resources.titles.checkYourEmail,
            otpEmailSent: stringHelper.formatValues(resources.messages.otpEmailSent, [newEmail]),
            enterCode: resources.messages.enterCode,
            emailWithoutAccess: stringHelper.formatValues(resources.messages.emailWithoutAccess, [newEmail]),
            otpCodeExpiredText: resources.messages.otpCodeExpired,
            otpCodeWrongText: resources.messages.otpCodeWrong,
            otpCodeLabel: resources.labels.otpCode,
            otpCodeValue: '',
            next: resources.buttons.goIntroduceOtpCode,
            vProps: {
                variant: "outlined",
                density: "compact",
            },
            otpCodeRules: validationRulesService.getValidationRules().otpCode,
            loading: false,
            errorTypes: [ErrorType.OtpCode, ErrorType.OtpLogin],
            buttonSave: resources.buttons.save,
            isValid: false,
            hasAccountReachedItsLimit: false,
            otpCodeExpired: false,
            otpCodeWrong: false,
            clickHereText: resources.links.clickHere,
            requestNewCodeText: resources.links.requestNewCode,
            accountReachedItsLimitText: resources.errors.accountReachedItsLimit,
            rateLimitsExceeded: false,
            rateLimitsExceededText: resources.errors.rateLimitsExceeded
        }
    },
    methods: {
        async submitForm() {
            if (!this.isValid)
                return;

            this.loading = true;
            this.otpCodeExpired = false;
            this.otpCodeWrong = false;
            this.hasAccountReachedItsLimit = false;
            this.rateLimitsExceeded = false;
            blockNavigationService.isNavigationBlocked = true;

            const userMetadata = auth0Client.user.value[Auth0IdTokenClaimsKeys.UserMetadata] ?? {};
            if (!userMetadata.alreadyUsedOtpCode || userMetadata.otpCode !== this.otpCodeValue) {

                const response = await otpCodeLoginService.handle({ code: this.otpCodeValue }, false);

                if (!response.isSuccess) {
                    await this.manageError(response, userMetadata);
                    this.loading = false;
                    blockNavigationService.isNavigationBlocked = false;
                    return;
                }

                //refresh the cache for the new user_metadata fields to be included in the model
                await auth0Client.getAccessTokenSilently({
                    cacheMode: 'off'
                });
            }

            const updateEmailRequest: UpdateUserQuery = {
                Field: fieldType.email,
                Value: userMetadata.newEmail
            };

            const updateEmailResponse = await updateUserService.handle(updateEmailRequest, false);

            if (updateEmailResponse.isSuccess) {
                await auth0Client.logout({
                    async openUrl() {
                        //This is here empty to prevent the SDK to redirect to login, user 
                        //will click the button on passwordUpdated component to do so.
                    }
                });
                this.$router.push("/userProfile/changeEmail/emailUpdated");
            }


            this.loading = false;
            blockNavigationService.isNavigationBlocked = false;
        },
        async manageError(response: OtpLoginResponse, userMetadata: any): Promise<void> {

            this.otpCodeWrong = response.isWrongCode;
            this.rateLimitsExceeded = response.rateLimitsExceeded;

            const self = this;
            if (response.hasAccountReachedItsLimit) {

                //If there's an error inside tryManageOtpStart, an error will be populated at errorsService, therefore we don't want to show both messages
                this.hasAccountReachedItsLimit = await tryManageOtpStart({
                    email: userMetadata.newEmail,
                    auth0: auth0Client,
                    passwordlessEmailCodeService: passwordlessEmailCodeService,
                    updateUserMetadataNewEmailService: updateUserMetadataService,
                    onTrySendPasswordlessStartError: () => self.loading = false,
                    onUpdateUserError: () => self.loading = false
                });
            } else if (response.isCodeExpired) {

                //If there's an error inside tryManageOtpStart, an error will be populated at errorsService, therefore we don't want to show both messages
                this.otpCodeExpired = await tryManageOtpStart({
                    email: userMetadata.newEmail,
                    auth0: auth0Client,
                    passwordlessEmailCodeService: passwordlessEmailCodeService,
                    updateUserMetadataNewEmailService: updateUserMetadataService,
                    onTrySendPasswordlessStartError: () => self.loading = false,
                    onUpdateUserError: () => self.loading = false
                });
            }

        },
        async resendCode() {
            this.loading = true;
            const newEmail = auth0Client.user.value[Auth0IdTokenClaimsKeys.UserMetadata]?.newEmail;
            blockNavigationService.isNavigationBlocked = true;
            await passwordlessEmailCodeService.trySendPasswordlessStart(newEmail);

            const updateUserQuery: UpdateuserMetadataNewEmailQuery = {
                NewEmail: newEmail,
                TemporalUserId: passwordlessEmailCodeService.getTemporalUserId()
            };
            const response = await updateUserMetadataService.handle(updateUserQuery, false);

            if (!response.isSuccess) {
                this.loading = false;
                blockNavigationService.isNavigationBlocked = false;
                return;
            }
            //refresh the cache for the new user_metadata fields to be included in the model
            await auth0Client.getAccessTokenSilently({
                cacheMode: 'off'
            });
            this.loading = false;
            blockNavigationService.isNavigationBlocked = false;
        },
        async stopOtpProcess() {
            this.loading = true;
            blockNavigationService.isNavigationBlocked = true;
            const response = await stopOtpService.handle(undefined, false);
            if (!response.isSuccess) {
                blockNavigationService.isNavigationBlocked = false;
                return;
            }
            //refresh the cache for the new user_metadata fields to be included in the model
            await auth0Client.getAccessTokenSilently({
                cacheMode: 'off'
            });
            blockNavigationService.isNavigationBlocked = false;
            this.loading = false;
            this.$router.push('/userProfile/changeEmail')
        }
    },
    components: {
        ErrorsManager,
        RadialSpinner
    },
});
