
import { Auth0VueClient, useAuth0 } from "@auth0/auth0-vue";
import { defineComponent, inject, PropType } from "vue";
import { Services } from "@/core/models/metadata/services.ts";
import Resources from "@/core/models/resources.ts";

interface BurgerMenuItem {
  title: string;
  iconSvg: any;
  class?: string;
  alt: string;
  clickHandler: () => void;
}

let resources!: Resources;
let auth0!: Auth0VueClient;
export default defineComponent({
  name: "BurgerMenu",
  props: {
    burgerMenuIsOpen: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    resources = inject(Services.Resources) as Resources;
    const close = resources.buttons.close;
    const signOut = resources.buttons.signOut;

    auth0 = useAuth0();
    return {
      close: resources.buttons.close,
      signOut: resources.buttons.signOut,
      items: [
        {
          title: close,
          alt: close,
          clickHandler: this.handleClose,
          iconSvg: require("@/assets/images/close.svg"),
        },
        {
          title: signOut,
          alt: signOut,
          clickHandler: this.handleSignOut,
          iconSvg: require("@/assets/images/sign-out.svg"),
        },
      ] as BurgerMenuItem[],
    };
  },
  methods: {
    handleItemClick(title: string) {
      if (title === this.close) {
        this.handleClose();
      } else if (title === this.signOut) {
        this.handleSignOut();
      }
    },
    handleClose() {
      this.$emit("update:burgerMenuIsOpen", !this.burgerMenuIsOpen);
    },
    handleSignOut() {
      auth0.logout();
    },
  },
  mounted() {
    auth0 = useAuth0();
  },
});
