import { Auth0VueClient } from "@auth0/auth0-vue";
import { Auth0IdTokenClaimsKeys } from "../models/metadata/auth0IdTokenClaimsKeys.ts";
import { UpdateUserMetadataNewEmailService } from "../services/updateUserMetadataNewEmailService.ts";
import { PasswordlessEmailCodeService } from "../services/passwordlessEmailCodeService.ts";
import { UpdateuserMetadataNewEmailQuery } from "../models/queries/updateUserMetadataNewEmailQuery.ts";

export const auth0UserMetadataLoaded: (auth0: Auth0VueClient) => Promise<void> = (auth0: Auth0VueClient) => {
    return new Promise(res => {
      const intervalId = setInterval(() => {
        if(!auth0.user?.value[Auth0IdTokenClaimsKeys.UserMetadata]?.title)
          return;
        clearInterval(intervalId);
        res();
      }, 100);
    });
  };

  export interface ManageOtpStartArgument{
    email: string;
    auth0: Auth0VueClient;
    passwordlessEmailCodeService: PasswordlessEmailCodeService;
    updateUserMetadataNewEmailService: UpdateUserMetadataNewEmailService;
    onTrySendPasswordlessStartError: () => void;
    onUpdateUserError: () => void;
  }
  export const tryManageOtpStart: (argument: ManageOtpStartArgument) => Promise<boolean>
    = async (argument: ManageOtpStartArgument) =>
    {
      if (!await argument.passwordlessEmailCodeService.trySendPasswordlessStart(argument.email)) {
        argument.onTrySendPasswordlessStartError();
        return false;
      }

      const updateUserQuery: UpdateuserMetadataNewEmailQuery = {
        NewEmail: argument.email,
        TemporalUserId: argument.passwordlessEmailCodeService.getTemporalUserId()
      };
      const response = await argument.updateUserMetadataNewEmailService.handle(updateUserQuery, false);

      if (!response.isSuccess) {
        argument.onUpdateUserError();
        return false;
      }
      //refresh the cache for the new user_metadata fields to be included in the model
      await argument.auth0.getAccessTokenSilently({
        cacheMode: 'off'
      });
      return true;
    }