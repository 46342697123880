import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadialSpinner = _resolveComponent("RadialSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_RadialSpinner, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.notAuthenticated)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, _toDisplayString(_ctx.staticErrorMessage), 1),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)))
          }, "Log in")
        ]))
      : _createCommentVNode("", true)
  ], 64))
}