
import { Auth0VueClient, useAuth0 } from "@auth0/auth0-vue";
import { defineComponent, inject } from "vue";
import { Services } from "@/core/models/metadata/services.ts";
import Resources from "@/core/models/resources.ts";
import { ValidationRulesService } from "@/core/services/validationRulesService.ts";
import RadialSpinner from "../../shared/radialSpinner.vue";
import BlockableButton from "@/components/shared/blockableButton.vue";
import ErrorsManager from "../../shared/errorsManager.vue";
import { ErrorType } from "@/core/models/metadata/error.ts";
import { UpdateUserMetadataNewEmailService } from "@/core/services/updateUserMetadataNewEmailService.ts";
import { PasswordlessEmailCodeService } from "@/core/services/passwordlessEmailCodeService.ts";
import { Auth0IdTokenClaimsKeys } from "@/core/models/metadata/auth0IdTokenClaimsKeys.ts";
import { StringHelper } from "@/core/services/stringHelper.ts";
import { StopOtpService } from "@/core/services/stopOtpService.ts";
import { UserExistsService } from "@/core/services/userExistsService.ts";
import { tryManageOtpStart } from "@/core/common/functions.ts";
import { BlockNavigationService } from "@/core/services/blockNavigationService.ts";

let updateUserService!: UpdateUserMetadataNewEmailService;
let passwordlessEmailCodeService!: PasswordlessEmailCodeService;
let resources!: Resources;
let validationRulesService!: ValidationRulesService;
let auth0!: Auth0VueClient;
let stopOtpService!: StopOtpService;
let userExistsService!: UserExistsService;
let blockNavigationService!: BlockNavigationService;
export default defineComponent({
  name: "UpdateEmail",
  data() {
    updateUserService = inject(Services.UpdateUserMetadataNewEmail) as UpdateUserMetadataNewEmailService;
    resources = inject(Services.Resources) as Resources;
    validationRulesService = inject(Services.ValidationRules) as ValidationRulesService;
    passwordlessEmailCodeService = inject(Services.PasswordlessEmailCode) as PasswordlessEmailCodeService;
    stopOtpService = inject(Services.StopOtpCode) as StopOtpService;
    auth0 = useAuth0();
    const stringHelper: StringHelper = inject(Services.StringHelper) as StringHelper;
    userExistsService = inject(Services.UserExists) as UserExistsService;
    blockNavigationService = inject(Services.BlockNavigationService)!;
    return {
      newUserEmail: "",
      newUserEmailRepeated: "",
      title: resources.titles.changeEmail,
      buttonSave: resources.buttons.save,
      buttonBack: resources.buttons.back,
      loading: false,
      rulesForEmail: validationRulesService.getValidationRules().email,
      isValid: false,
      enterNewEmail: resources.messages.enterNewEmail,
      enterNewEmailLabel: resources.labels.enterNewEmail,
      confirmNewEmail: resources.labels.confirmNewEmail,
      errorMessageForNonEmailsEquality: resources.messages.emailsMustBeEqual,
      errorMessagesForRepeatedEmail: [] as string[],
      errorTypes: [ErrorType.UpdateField, ErrorType.StopOtpCode, ErrorType.OtpCode, ErrorType.EmailAlreadyTaken, ErrorType.General],
      resendVerificationEmail: resources.buttons.resendVerificationEmail,
      hasStartedEmailChange: auth0.user.value[Auth0IdTokenClaimsKeys.UserMetadata]?.hasStartedEmailChange ?? false,
      emailChangeInProgressText: stringHelper.formatValues(resources.messages.emailChangeInProgressText, [auth0.user.value[Auth0IdTokenClaimsKeys.UserMetadata].newEmail]),
      stopOtpProcessText: resources.messages.stopOtpProcess,
      continueOtpProcessText: resources.messages.continueOtpProcess
    };
  },
  methods: {
    async submitForm() {
      if (!this.isValid) return;
      this.loading = true;
      blockNavigationService.isNavigationBlocked = true;
      const userExistsResponse = await userExistsService.handle({
        email: this.newUserEmail
      }, false);

      if (userExistsResponse.exists || !userExistsResponse.isSuccess) {
        this.loading = false;
        blockNavigationService.isNavigationBlocked = false;
        return;
      }
      const self = this;
      if (!await tryManageOtpStart({
        email: this.newUserEmail,
        auth0: auth0,
        passwordlessEmailCodeService: passwordlessEmailCodeService,
        updateUserMetadataNewEmailService: updateUserService,
        onTrySendPasswordlessStartError: () => self.loading = false,
        onUpdateUserError: () => self.loading = false
      })) {
        blockNavigationService.isNavigationBlocked = false;
        return;
      }
      blockNavigationService.isNavigationBlocked = false;
      this.loading = false;
      this.$router.push('/userProfile/changeEmail/OtpSent');

    },
    checkEmailsEquality() {
      if (this.newUserEmailRepeated.length > 0 &&
        this.newUserEmail !== this.newUserEmailRepeated)
        this.errorMessagesForRepeatedEmail = [
          this.errorMessageForNonEmailsEquality,
        ];
      else this.errorMessagesForRepeatedEmail = [];
    },
    async stopOtpProcess() {
      this.loading = true;
      blockNavigationService.isNavigationBlocked = true;
      const response = await stopOtpService.handle(undefined, false);
      if (!response.isSuccess) {
        blockNavigationService.isNavigationBlocked = false;
        return;
      }
      //refresh the cache for the new user_metadata fields to be included in the model
      await auth0.getAccessTokenSilently({
        cacheMode: 'off'
      });
      this.hasStartedEmailChange = auth0.user.value[Auth0IdTokenClaimsKeys.UserMetadata]?.hasStartedEmailChange ?? false;
      blockNavigationService.isNavigationBlocked = false;
      this.loading = false;
    },
    continueOtpProcess() {
      this.$router.push('/userProfile/changeEmail/OtpSent');
    }
  },
  computed: {
    isDisabled() {
      return {
        "disabled-button": !this.isValid,
      };
    },
    vProps() {
      return {
        variant: "outlined",
        density: "compact",
      };
    },
  },
  components: {
    RadialSpinner,
    BlockableButton,
    ErrorsManager,
  },
});
