
import { useAuth0 } from "@auth0/auth0-vue";
import { Auth0IdTokenClaimsKeys } from "@/core/models/metadata/auth0IdTokenClaimsKeys.ts";
import { defineComponent, inject } from "vue";
import { Services } from "@/core/models/metadata/services.ts";
import { UpdateUserNamesQuery } from "@/core/models/queries/updateUserQuery.ts";
import Resources from "@/core/models/resources.ts";
import { ValidationRulesService } from "@/core/services/validationRulesService.ts";
import RadialSpinner from "@/components/shared/radialSpinner.vue";
import BlockableButton from "@/components/shared/blockableButton.vue";
import { UpdateUserNamesService } from "@/core/services/updateUserNamesService.ts";
import { Title } from "@/core/models/queries/fieldTypeEnum.ts";
import ErrorsManager from '../../shared/errorsManager.vue'
import { ErrorType } from "@/core/models/metadata/error.ts";

let updateUserNamesService!: UpdateUserNamesService;
let resources!: Resources;
let validationRulesService!: ValidationRulesService;

export default defineComponent({
  name: "UpdateName",
  data() {
    updateUserNamesService = inject(
      Services.UpdateUserNames
    ) as UpdateUserNamesService;
    resources = inject(Services.Resources) as Resources;
    validationRulesService = inject(
      Services.ValidationRules
    ) as ValidationRulesService;
    const auth0 = useAuth0();
    return {
      newUserFirstName:
        auth0.user?.value?.given_name ??
        "",
      newUserLastName:
        auth0.user?.value?.family_name ?? "",
      newUserTitle:
        auth0.user?.value[Auth0IdTokenClaimsKeys.UserMetadata]?.title ?? "",
      buttonSave: resources.buttons.save,
      buttonBack: resources.buttons.back,
      changeYourName: resources.titles.changeYourName,
      loading: false,
      rules: validationRulesService.getValidationRules(),
      isValid: false,
      enterNewFirstNameLabel: resources.messages.firstName,
      enterNewLastNameLabel: resources.messages.lastName,
      enterTitleLabel: resources.messages.title,
      titles: Object.values(Title),
      errorType: ErrorType.UpdateName,
      changeTitle: "changeTitle"
    };
  },
  methods: {
    async submitForm() {
      if(!this.isValid) return
      this.loading = true;
      const updateUserNamesQuery: UpdateUserNamesQuery = {
        title: this.newUserTitle,
        firstName: this.newUserFirstName,
        lastName: this.newUserLastName,
      };
      const response = await updateUserNamesService.handle(
        updateUserNamesQuery
      );
      this.loading = false;
      if(response.isSuccess)
        this.$router.push("/?intendedAccessPath=/userProfile/changeName/personalInfoUpdated");
    },
  },
  computed: {
    isDisabled() {
      return {
        "disabled-button": !this.isValid,
      };
    },
    vProps() {
      return {
        variant: "outlined",
        density: "compact",
      };
    },
  },
  components: {
    RadialSpinner,
    BlockableButton,
    ErrorsManager
  },
});
