import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "componentTitle" }
const _hoisted_2 = { class: "cardMainContent" }
const _hoisted_3 = { class: "descriptionTextMb" }
const _hoisted_4 = { class: "descriptionTextMb" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlockableButton = _resolveComponent("BlockableButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.changeYourName), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.nameUpdated), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.shortWhile), 1),
      _createVNode(_component_BlockableButton, {
        class: "whiteBgButton",
        route: "/userProfile",
        buttonText: _ctx.backToDetails
      }, null, 8, ["buttonText"])
    ])
  ], 64))
}