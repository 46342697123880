import { Auth0Plugin } from "@auth0/auth0-vue";
import { BaseResponse } from "../models/responses/baseResponse.ts";
import { ErrorsService } from "./errorsService.ts";
import { BlockNavigationService } from "./blockNavigationService.ts";
import { ErrorType } from "../models/metadata/error.ts";
import { JwtsWrapper } from "./_jwtsWrapper.ts";

export abstract class BackendServiceBase<TQuery, TResponse extends BaseResponse> {

    private readonly maxRetriesForUnauthorizedResponse: number = 3;
    private currentNumberOfRetriesForUnautorizedResponse: number = 0;
    private readonly invariantHeaders = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
    constructor(
        private readonly blockNavigationService: BlockNavigationService,
        protected readonly errorsService: ErrorsService,
        protected readonly auth0Plugin: Auth0Plugin,
        protected readonly backendEndpoint: string,
        private readonly errorType: ErrorType = ErrorType.Silent
        ) { }

    protected async getHeaders(): Promise<any> {
        if(!this.auth0Plugin.isAuthenticated.value)
            return this.invariantHeaders;

        const jwt = await JwtsWrapper.getJwt(this.auth0Plugin);
        return {
            ...this.invariantHeaders,
            'Authorization': `Bearer ${jwt}`
        };
    }
    protected defineErrorMessage(query?: TQuery): string {
        return '';
    }
    protected onSuccess(response: TResponse): void | Promise<void>{

    }
    protected onStart(): void | Promise<void>{

    }
    protected async successErrorChallenge(result: TResponse, query?: TQuery): Promise<void>{
        if (!result.isSuccess) {
            this.introduceErrorIfNotEmpty(query);
        }else{
            await this.onSuccess(result);
        }
    }
    protected onException(query?: TQuery): void{
        this.introduceErrorIfNotEmpty(query);
    }
    protected abstract buildFetch(headers: any, query?: TQuery): Promise<Response>;
    public async handle(query?: TQuery, forceNavigationBlocked: boolean = true): Promise<TResponse> {
        try {
            this.errorsService.deleteByType(this.errorType);
            await this.onStart();
            if (forceNavigationBlocked)
                this.blockNavigationService.isNavigationBlocked = true;

            if (this.currentNumberOfRetriesForUnautorizedResponse === this.maxRetriesForUnauthorizedResponse) {
                this.currentNumberOfRetriesForUnautorizedResponse = 0;
                throw Error();
            }

            const response = await this.buildFetch(await this.getHeaders(), query);

            if (response.status === 401) {
                JwtsWrapper.setJwtAsRotted();
                this.currentNumberOfRetriesForUnautorizedResponse++;
                return await this.handle(query);
            }

            const json = await response.json();
            const result = json as TResponse;

            await this.successErrorChallenge(result, query);
            if (forceNavigationBlocked)
                this.blockNavigationService.isNavigationBlocked = false;
            return result;
        } catch (e) {
            this.onException(query);
            if (forceNavigationBlocked)
                this.blockNavigationService.isNavigationBlocked = false;
            return new Promise((res, _) => res({ isSuccess: false, FailureMessage: '' } as TResponse));
        }
    }
    protected introduceErrorIfNotEmpty(query?: TQuery): void {
        const error = this.defineErrorMessage(query);
        if (error)
            this.errorsService.push(error, this.errorType);
    }

}
