
import { defineComponent, inject } from "vue";
import { UpdateUserService } from "@/core/services/updateUserService.ts";
import { Services } from "@/core/models/metadata/services.ts";
import { UpdateUserQuery } from "@/core/models/queries/updateUserQuery.ts";
import { fieldType } from "@/core/models/queries/fieldTypeEnum.ts";
import Resources from "@/core/models/resources.ts";
import { ValidationRulesService } from "@/core/services/validationRulesService.ts";
import RadialSpinner from "../../shared/radialSpinner.vue";
import BlockableButton from "@/components/shared/blockableButton.vue";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import ErrorsManager from "../../shared/errorsManager.vue";
import { ErrorType } from "@/core/models/metadata/error.ts";
import { Auth0VueClient, useAuth0 } from "@auth0/auth0-vue";

let updateUserService!: UpdateUserService;
let resources!: Resources;
let validationRulesService!: ValidationRulesService;
let auth0!: Auth0VueClient;
export default defineComponent({
  name: "UpdateEmail",
  data() {
    updateUserService = inject(Services.UpdateUser) as UpdateUserService;
    resources = inject(Services.Resources) as Resources;
    validationRulesService = inject(
      Services.ValidationRules
    ) as ValidationRulesService;
    auth0 = useAuth0();
    return {
      buttonBack: resources.buttons.back,
      buttonSave: resources.buttons.save,
      confirmPassword: resources.titles.confirmPassword,
      enterPassword: resources.titles.enterPassword,
      enterNewPasswordDescription:
        resources.messages.enterNewPasswordDescription,
      errorMessagesForRepeatedPassword: [] as string[],
      errorMessageForNonPasswordsEquality:
        resources.messages.passwordEqualityError,
      hint: resources.messages.hint,
      icons: { 
        selectedNew: mdiEye,
        selectedRepeated: mdiEye,
      },
      isValid: false,
      loading: false,
      newUserPassword: "",
      newUserPasswordRepeated: "",
      passwordInputType: "password",
      passwordInputTypeRepeated: "password",
      rules: validationRulesService.getValidationRules().password,
      rulesRepeatEmail:
        validationRulesService.getValidationRules().repeatPassword,
      title: resources.titles.changePassword,
      youWillBeSignedOut: resources.messages.youWillBeSignedOut,
      errorType: ErrorType.UpdateField,
      isLengthRuleValid: false,
      isLowerCaseRuleValid: false,
      isUpperCaseRuleValid: false,
      isNumberRuleValid: false,
      isSpecialCharactersRuleValid: false,
      pleaseEnterPasswordThat: resources.messages.pleaseEnterPasswordThat,
      lengthRuleText: resources.messages.passwordStrenghtMinCharacters,
      threeOfTheFollowingText: resources.messages.threeOfThefollowing,
      lowerCaseRuleText: resources.messages.passwordStrengthLowerCase,
      upperCaseRuleText: resources.messages.passwordStrengthUpperCase,
      numberRuleText: resources.messages.passwordStrengthNumber,
      specialCharactersRuleText: resources.messages.passwordStrengthSpecialCharacter,
    };
  },
  methods: {
    async submitForm() {
      if (!this.isValid) return;
      this.loading = true;
      const updateUserQuery: UpdateUserQuery = {
        Field: fieldType.password,
        Value: this.newUserPassword,
      };
      const response = await updateUserService.handle(updateUserQuery);
      this.loading = false;
      if (!response.isSuccess) {
        return;
      }

      await auth0.logout({
        async openUrl() {
          //This is here empty to prevent the SDK to redirect to login, user 
          //will click the button on passwordUpdated component to do so.
        }
      });
      this.$router.push("/userProfile/changePassword/passwordUpdated");
    },
    getIcon(inputField: string) {
      return inputField === "selectedNew"
        ? this.icons.selectedNew
        : this.icons.selectedRepeated;
    },
    onInnerIconClick(inputField: string) {
      const toggleIcon = (icon: string) =>
        icon === mdiEyeOff ? mdiEye : mdiEyeOff;
      if (inputField === "selectedNew") {
        this.icons.selectedNew = toggleIcon(this.icons.selectedNew);
        this.passwordInputType =
          this.icons.selectedNew === mdiEyeOff ? "text" : "password";
      } else if (inputField === "selectedRepeated") {
        this.icons.selectedRepeated = toggleIcon(this.icons.selectedRepeated);
        this.passwordInputTypeRepeated =
          this.icons.selectedRepeated === mdiEyeOff ? "text" : "password";
      }
    },
    getPasswordInputType(inputField: string) {
      return inputField === "selectedNew"
        ? this.passwordInputType
        : this.passwordInputTypeRepeated;
    },
    validateAndCheckPasswordsEquality(){
      this.isLengthRuleValid = this.newUserPassword?.length >= 8;
      this.isNumberRuleValid = /\d/.test(this.newUserPassword);
      this.isLowerCaseRuleValid = /[a-z]/.test(this.newUserPassword);
      this.isUpperCaseRuleValid = /[A-Z]/.test(this.newUserPassword);
      this.isSpecialCharactersRuleValid = /[!¡"@#$%^&*)(+=._-]/.test(this.newUserPassword);
      this.checkPasswordsEquality();
    },
    checkPasswordsEquality() {
      if (
        this.newUserPasswordRepeated.length > 0 &&
        this.newUserPassword !== this.newUserPasswordRepeated
      )
        this.errorMessagesForRepeatedPassword = [
          this.errorMessageForNonPasswordsEquality,
        ];
      else this.errorMessagesForRepeatedPassword = [];
    }
  },
  computed: {
    isDisabled() {
      return {
        "disabled-button": !this.isValid,
      };
    },
    vProps() {
      return {
        variant: "outlined",
        density: "compact",
      };
    },
    lengthRuleClass(){
      return {
        "success-message": this.isLengthRuleValid,
      };
    },
    threeOfTheFollowingRuleClass(){
      const rulesResult = [ this.isLowerCaseRuleValid, this.isUpperCaseRuleValid, this.isNumberRuleValid, this.isSpecialCharactersRuleValid ];
      return {
        "success-message": rulesResult.filter(result => result).length >= 3,
      };
    },
    lowerCaseRuleClass(){
      return {
        "success-message": this.isLowerCaseRuleValid,
      };
    },
    upperCaseRuleClass(){
      return {
        "success-message": this.isUpperCaseRuleValid,
      };
    },
    numberRuleClass(){
      return {
        "success-message": this.isNumberRuleValid
      };
    },
    specialCharacterRuleClass(){
      return {
        "success-message": this.isSpecialCharactersRuleValid,
      };
    },
    vInputDetailsClass(){
      const rulesResult = [ this.isLowerCaseRuleValid, this.isUpperCaseRuleValid, this.isNumberRuleValid, this.isSpecialCharactersRuleValid ];
      return {
        "v-input__details-success": rulesResult.filter(result => result).length >= 3 && this.isLengthRuleValid,
      };
    }
  },
  components: {
    RadialSpinner,
    BlockableButton,
    ErrorsManager,
  },
});
