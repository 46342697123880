export enum ContactFormArea{
    Books = "Books",
    FaradayForum = "Faraday Forum",
    ChangeMakers = "Change Makers",
    Membership = "Membership",
    VolunteerHub = "Volunteer Hub",
    Donations = "Donations"
}
const frontToBackendCorrespondence: any = {
}
frontToBackendCorrespondence[ContactFormArea.Books] = 0
frontToBackendCorrespondence[ContactFormArea.FaradayForum] = 1
frontToBackendCorrespondence[ContactFormArea.ChangeMakers] = 2
frontToBackendCorrespondence[ContactFormArea.Membership] = 3
frontToBackendCorrespondence[ContactFormArea.VolunteerHub] = 4
frontToBackendCorrespondence[ContactFormArea.Donations] = 5
export function contactFormAreaToServerEnum(value: ContactFormArea): number{
    return frontToBackendCorrespondence[value];
}
export function contactFormAreaToFrontEndEnum(value: number): ContactFormArea{
    return frontToBackendCorrespondence[value];
}