
import { defineComponent, inject } from "vue";
import { Services } from "@/core/models/metadata/services.ts";
import Resources from "@/core/models/resources.ts";
import { BlockNavigationService } from "@/core/services/blockNavigationService.ts";
import OneTrustCookie from '../components/OneTrustCookie.vue';


let resources!: Resources;
let blockNavigationService!: BlockNavigationService;
export default defineComponent({
  name: "AppFooter",
  data() {
    resources = inject(Services.Resources) as Resources;
    blockNavigationService = inject(Services.BlockNavigationService) as BlockNavigationService;
    return {
      accesibilityLink: resources.links.accesibility,
      cookies: resources.messages.cookies,
      cookiesLink: resources.links.cookies,
      copyrightRegistered: resources.messages.copyrightRegistered,
      privacyLink: resources.links.privacy,
      legal: resources.labels.legal,
      legalLink: resources.links.legal,
      privacy: resources.labels.privacy,
      accesibility: resources.labels.accesibility,
    };
  },
  methods: {
    goToUrl(url: string) {
      if (blockNavigationService.isNavigationBlocked) return;
      window.location.href = url;
    },
  },
  components: {
    OneTrustCookie,
  },
});
