import { Auth0Plugin } from "@auth0/auth0-vue";
import { ErrorType } from "../models/metadata/error.ts";
import { UserExistsQuery } from "../models/queries/userExistsQuery.ts";
import Resources from "../models/resources.ts";
import { UserExistsResponse } from "../models/responses/userExistsResponse.ts";
import { BackendServiceBase } from "./backendServiceBase.ts";
import { BlockNavigationService } from "./blockNavigationService.ts";
import { ErrorsService } from "./errorsService.ts";

export class UserExistsService extends BackendServiceBase<UserExistsQuery, UserExistsResponse>{

    constructor(private readonly resources: Resources, blockNavigationService: BlockNavigationService, errorsService: ErrorsService, auth0Plugin: Auth0Plugin, backendEndpoint: string) {
        super(blockNavigationService, errorsService, auth0Plugin, backendEndpoint, ErrorType.General);
    }

    protected override defineErrorMessage(query: UserExistsQuery): string {

        return this.resources.errors.general;
    }
    
    protected override onSuccess(response: UserExistsResponse): void | Promise<void> {

        if(!response.exists)
            return;

        this.errorsService.push(this.resources.errors.emailAlreadyTaken, ErrorType.EmailAlreadyTaken);
    }
    
    protected override onStart(): void | Promise<void> {

        this.errorsService.deleteByType(ErrorType.EmailAlreadyTaken);
    }

    protected buildFetch(headers: any, query: UserExistsQuery): Promise<Response> {        
        return fetch(`${this.backendEndpoint}/userExists?email=${query.email}`, {
            headers: headers,
            method: 'GET'
        });
    }

}