
import { defineComponent } from 'vue'
import RadialSpinner from './shared/radialSpinner.vue'
import { Auth0Plugin, useAuth0 } from '@auth0/auth0-vue';
import RemoteOriginatingSiteService from '@/core/services/remoteOriginatingSiteService.ts';
import { inject } from 'vue';
import { Services } from '@/core/models/metadata/services.ts';
import { JwtsWrapper } from '@/core/services/_jwtsWrapper.ts';

let remoteOriginatingSiteService!: RemoteOriginatingSiteService;
const getPath = (path: string) => {
    const splittedPath = path.split('?');
    if(splittedPath.length === 1)
        return path.replaceAll('/', '') ? path : '/userProfile';
    const actualPath = splittedPath[0];
    return actualPath.replaceAll('/', '') ? path : `/userProfile?${splittedPath[1]}`;
};
export default defineComponent({
    name: 'LoginNode',
    mounted() {
        remoteOriginatingSiteService = inject(Services.RemoteOriginatingSiteService) as RemoteOriginatingSiteService;

        const auth0 = useAuth0();
        const router = this.$router;
        remoteOriginatingSiteService.saveUrlAtLocalStorage()
            .then(async () => {

                const path = await remoteOriginatingSiteService.getIntendedAccessPath();
                const processedPath = getPath(path);
                await JwtsWrapper.checkSession(auth0 as Auth0Plugin);


                if (auth0.isAuthenticated.value) {
                    router.push(processedPath)
                    return;
                }

                auth0.loginWithRedirect({
                    authorizationParams: {
                        redirect_uri: `${process.env.VUE_APP_AUTH0_REDIRECT_URI}?intendedAccessPath=${encodeURIComponent(processedPath)}`
                    }
                });
            });

    },
    components: {
        RadialSpinner
    }
});
