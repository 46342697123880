
import { defineComponent, inject, reactive, ref } from "vue";
import { Auth0VueClient, useAuth0 } from "@auth0/auth0-vue";
import { Services } from "@/core/models/metadata/services.ts";
import Resources from "@/core/models/resources.ts";
import BurgerMenu from "../components/burgerMenu.vue";
import { UnwrapNestedRefs } from "vue";

let auth0!: UnwrapNestedRefs<Auth0VueClient>;
let resources!: Resources;
export default defineComponent({
  name: "AppHeader",
  data() {
    auth0 = reactive(useAuth0());
    resources = inject(Services.Resources) as Resources;
    return {
      signOut: resources.buttons.signOut,
      burgerMenuIsOpen: false,
      reactiveClient: ref(auth0)
    };
  },
  computed:{
    isAuthenticated(): boolean | undefined { return this.reactiveClient?.isAuthenticated }
  },
  methods: {
    logOut() {
      auth0.logout();
    },
    handleBurgerMenu() {
      this.burgerMenuIsOpen = !this.burgerMenuIsOpen;
      this.$emit("update:burgerMenuIsOpen", this.burgerMenuIsOpen);
    },
  },
  components: {
    BurgerMenu,
  },
});
