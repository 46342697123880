import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, mergeProps as _mergeProps, withModifiers as _withModifiers, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7df703f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "changeEmailTitle",
  class: "componentTitle"
}
const _hoisted_2 = { class: "cardMainContent" }
const _hoisted_3 = { class: "descriptionTextMb" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = {
  key: 1,
  class: "buttonsBox"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  id: "newEmailLabel",
  class: "label"
}
const _hoisted_9 = {
  id: "confirmEmailLabel",
  class: "label"
}
const _hoisted_10 = { class: "buttonsBox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorsManager = _resolveComponent("ErrorsManager")!
  const _component_RadialSpinner = _resolveComponent("RadialSpinner")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_BlockableButton = _resolveComponent("BlockableButton")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_form, {
        "validate-on": "submit",
        onSubmit: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"])),
        modelValue: _ctx.isValid,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isValid) = $event))
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.enterNewEmail), 1),
          (_ctx.hasStartedEmailChange)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.emailChangeInProgressText), 1),
                _createVNode(_component_ErrorsManager, { errorTypes: _ctx.errorTypes }, null, 8, ["errorTypes"]),
                (_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_RadialSpinner, { key: 0 }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("button", {
                        type: "submit",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.stopOtpProcess && _ctx.stopOtpProcess(...args))),
                        class: "blueBgButton"
                      }, _toDisplayString(_ctx.stopOtpProcessText), 1),
                      _createElementVNode("button", {
                        type: "submit",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.continueOtpProcess && _ctx.continueOtpProcess(...args))),
                        class: "blueBgButton"
                      }, _toDisplayString(_ctx.continueOtpProcessText), 1)
                    ]))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.enterNewEmailLabel), 1),
                _createVNode(_component_v_text_field, _mergeProps({ id: "newEmail" }, _ctx.vProps, {
                  modelValue: _ctx.newUserEmail,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newUserEmail) = $event)),
                  rules: _ctx.rulesForEmail,
                  onblur: _ctx.checkEmailsEquality,
                  "validate-on": "blur"
                }), null, 16, ["modelValue", "rules", "onblur"]),
                _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.confirmNewEmail), 1),
                _createVNode(_component_v_text_field, _mergeProps({ id: "confirmEmail" }, _ctx.vProps, {
                  modelValue: _ctx.newUserEmailRepeated,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newUserEmailRepeated) = $event)),
                  rules: _ctx.rulesForEmail,
                  "error-messages": _ctx.errorMessagesForRepeatedEmail,
                  onblur: _ctx.checkEmailsEquality,
                  "validate-on": "blur"
                }), null, 16, ["modelValue", "rules", "error-messages", "onblur"]),
                _createVNode(_component_ErrorsManager, { errorTypes: _ctx.errorTypes }, null, 8, ["errorTypes"]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_BlockableButton, {
                    idTag: "changeEmailBack",
                    route: "/userProfile",
                    buttonText: _ctx.buttonBack,
                    underlined: ""
                  }, null, 8, ["buttonText"]),
                  (_ctx.loading)
                    ? (_openBlock(), _createBlock(_component_RadialSpinner, { key: 0 }))
                    : (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        id: "changeEmailSave",
                        type: "submit",
                        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args))),
                        class: "blueBgButton"
                      }, _toDisplayString(_ctx.buttonSave), 1))
                ])
              ]))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ], 64))
}