import { Auth0Plugin } from "@auth0/auth0-vue";
import { BackendServiceBase } from "./backendServiceBase.ts";
import { RemoteOriginatingSiteResponse } from "../models/responses/remoteOriginatingSiteResponse.ts";
import { IsValidOriginQuery } from "../models/queries/isValidOriginQuery.ts";
import { ErrorsService } from "./errorsService.ts";
import { BlockNavigationService } from "./blockNavigationService.ts";
import { ErrorType } from "../models/metadata/error.ts";

export class IsValidEndpointService extends BackendServiceBase<IsValidOriginQuery, RemoteOriginatingSiteResponse>{

    constructor(blockNavigationService: BlockNavigationService, errorsService: ErrorsService, auth0Plugin: Auth0Plugin, backendEndpoint: string) {
        super(blockNavigationService, errorsService, auth0Plugin, backendEndpoint, ErrorType.Silent);
    }

    protected buildFetch(headers: any, query?: IsValidOriginQuery): Promise<Response> {
        return fetch(`${this.backendEndpoint}/isValidOrigin`, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(query)
        });
    }
}