
import { Auth0VueClient, useAuth0 } from "@auth0/auth0-vue";
import { defineComponent, inject } from "vue";
import Resources from "../core/models/resources.ts";
import { Services } from "../core/models/metadata/services.ts";
import { EmailVerificationService } from "../core/services/emailVerificationService.ts";
import RadialSpinner from "./shared/radialSpinner.vue";
import LeftPanel from "./userProfile/leftPanel.vue";
import RemoteOriginatingSiteService from "@/core/services/remoteOriginatingSiteService.ts";
import { RemoteOriginatingSiteResponse } from "@/core/models/responses/remoteOriginatingSiteResponse.ts";
import ErrorsManager from "./shared/errorsManager.vue";
import { ErrorType } from "@/core/models/metadata/error.ts";

var emailVerificationService: EmailVerificationService;
let remoteOriginatingSiteService!: RemoteOriginatingSiteService;
let auth0!: Auth0VueClient;
let buttonInfo: RemoteOriginatingSiteResponse = {
  isSuccess: false,
  FailureMessage: "",
  title: "",
  url: "",
};
export default defineComponent({
  mounted() {
    remoteOriginatingSiteService = inject(
      Services.RemoteOriginatingSiteService
    ) as RemoteOriginatingSiteService;
    remoteOriginatingSiteService.retrieveUrlAndTitle().then((response) => {
      this.buttonInfo = response;
      this.buttonInfoLoading = false;
    });
  },
  data() {
    let resources = inject(Services.Resources) as Resources;
    emailVerificationService = inject(
      Services.EmailVerification
    ) as EmailVerificationService;
    auth0 = useAuth0();
    return {
      emailText: resources.buttons.resendEmail,
      emailTitle: resources.titles.verifyYourEmail,
      validationEmailSent: resources.messages.validationEmailSent,
      clickLinkToEmailChange: resources.messages.clickLinkToEmailChange,
      emailActiveUntilDone: resources.messages.emailActiveUntilDone,
      isLoading: false,
      newUserEmail: auth0.user.value.email,
      isAuthenticated: auth0.isAuthenticated.value && (auth0.user.value?.email_verified ?? false),
      buttonInfo: buttonInfo,
      buttonInfoLoading: true,
      errorType: ErrorType.ResendEmailVerification,
    };
  },
  methods: {
    async sendEmailVerificationRequest() {
      this.isLoading = true;
      await emailVerificationService.handle();
      this.isLoading = false;
    },
  },
  components: {
    RadialSpinner,
    LeftPanel,
    ErrorsManager,
  },
});
