import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41d8220b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "aside aside-right" }
const _hoisted_3 = {
  id: "updatedPasswordTitle",
  class: "componentTitle"
}
const _hoisted_4 = { class: "cardMainContent" }
const _hoisted_5 = { class: "descriptionTextMb" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeftPanel = _resolveComponent("LeftPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LeftPanel, {
      buttonInfo: _ctx.buttonInfo,
      buttonInfoLoading: _ctx.buttonInfoLoading,
      isAuthenticated: false
    }, null, 8, ["buttonInfo", "buttonInfoLoading"]),
    _createElementVNode("aside", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.passwordUpdated), 1),
        _createElementVNode("button", {
          class: "whiteBgButton",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logIn && _ctx.logIn(...args)))
        }, _toDisplayString(_ctx.signInWithNewPassword), 1)
      ])
    ])
  ]))
}