import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51c813de"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "changePasswordTitle",
  class: "componentTitle"
}
const _hoisted_2 = { class: "cardMainContent" }
const _hoisted_3 = { class: "descriptionText" }
const _hoisted_4 = { class: "warningBox" }
const _hoisted_5 = { class: "textWithPadding" }
const _hoisted_6 = {
  id: "newPasswordLabel",
  class: "label"
}
const _hoisted_7 = {
  class: "v-messages",
  role: "alert",
  "aria-live": "polite"
}
const _hoisted_8 = { class: "v-messages-title" }
const _hoisted_9 = { class: "v-messages v-messages-inner" }
const _hoisted_10 = {
  id: "confirmPasswordLabel",
  class: "label"
}
const _hoisted_11 = { class: "buttonsBox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_ErrorsManager = _resolveComponent("ErrorsManager")!
  const _component_BlockableButton = _resolveComponent("BlockableButton")!
  const _component_RadialSpinner = _resolveComponent("RadialSpinner")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_form, {
        "validate-on": "submit",
        onSubmit: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"])),
        modelValue: _ctx.isValid,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isValid) = $event))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.enterNewPasswordDescription), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.youWillBeSignedOut), 1)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.enterPassword), 1),
            _createVNode(_component_v_text_field, _mergeProps({
              id: "newPassword",
              "validate-on": "input",
              class: "input main-password"
            }, _ctx.vProps, {
              modelValue: _ctx.newUserPassword,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newUserPassword) = $event)),
              rules: _ctx.rules,
              "append-inner-icon": _ctx.getIcon('selectedNew'),
              "onClick:appendInner": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInnerIconClick('selectedNew'))),
              type: _ctx.getPasswordInputType('selectedNew'),
              oninput: _ctx.validateAndCheckPasswordsEquality,
              "max-errors": "15"
            }), null, 16, ["modelValue", "rules", "append-inner-icon", "type", "oninput"]),
            _createElementVNode("div", {
              class: _normalizeClass(["v-input__details", _ctx.vInputDetailsClass])
            }, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.pleaseEnterPasswordThat), 1),
                _createElementVNode("div", {
                  class: _normalizeClass(["v-messages__message", _ctx.lengthRuleClass])
                }, _toDisplayString(_ctx.lengthRuleText), 3),
                _createElementVNode("div", {
                  class: _normalizeClass(["v-messages__message", _ctx.threeOfTheFollowingRuleClass])
                }, _toDisplayString(_ctx.threeOfTheFollowingText), 3),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["v-messages__message", _ctx.numberRuleClass])
                  }, _toDisplayString(_ctx.numberRuleText), 3),
                  _createElementVNode("div", {
                    class: _normalizeClass(["v-messages__message", _ctx.lowerCaseRuleClass])
                  }, _toDisplayString(_ctx.lowerCaseRuleText), 3),
                  _createElementVNode("div", {
                    class: _normalizeClass(["v-messages__message", _ctx.upperCaseRuleClass])
                  }, _toDisplayString(_ctx.upperCaseRuleText), 3),
                  _createElementVNode("div", {
                    class: _normalizeClass(["v-messages__message", _ctx.specialCharacterRuleClass])
                  }, _toDisplayString(_ctx.specialCharactersRuleText), 3)
                ])
              ])
            ], 2)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.confirmPassword), 1),
            _createVNode(_component_v_text_field, _mergeProps({
              id: "confirmPassword",
              "validate-on": "input",
              class: "input"
            }, _ctx.vProps, {
              modelValue: _ctx.newUserPasswordRepeated,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newUserPasswordRepeated) = $event)),
              rules: _ctx.rulesRepeatEmail,
              "append-inner-icon": _ctx.getIcon('selectedRepeated'),
              "onClick:appendInner": _cache[3] || (_cache[3] = ($event: any) => (_ctx.onInnerIconClick('selectedRepeated'))),
              type: _ctx.getPasswordInputType('selectedRepeated'),
              oninput: _ctx.checkPasswordsEquality,
              "error-messages": _ctx.errorMessagesForRepeatedPassword
            }), null, 16, ["modelValue", "rules", "append-inner-icon", "type", "oninput", "error-messages"])
          ]),
          _createVNode(_component_ErrorsManager, { errorType: _ctx.errorType }, null, 8, ["errorType"]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_BlockableButton, {
              idTag: "changePasswordBack",
              route: "/userProfile",
              buttonText: _ctx.buttonBack,
              underlined: ""
            }, null, 8, ["buttonText"]),
            (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_RadialSpinner, { key: 0 }))
              : (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  id: "changePasswordSave",
                  type: "submit",
                  class: "blueBgButton",
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)))
                }, _toDisplayString(_ctx.buttonSave), 1))
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ], 64))
}