
import { Services } from "@/core/models/metadata/services.ts";
import { defineComponent, inject } from "vue";
import LeftPanel from "../userProfile/leftPanel.vue";
import RadialSpinner from "../shared/radialSpinner.vue";
import BlockableButton from "@/components/shared/blockableButton.vue";
import Resources from "@/core/models/resources.ts";
import { useAuth0 } from "@auth0/auth0-vue";
import RemoteOriginatingSiteService from "@/core/services/remoteOriginatingSiteService.ts";
import { RemoteOriginatingSiteResponse } from "@/core/models/responses/remoteOriginatingSiteResponse.ts";

let resources!: Resources;
let remoteOriginatingSiteService!: RemoteOriginatingSiteService;
let buttonInfo: RemoteOriginatingSiteResponse = {
  isSuccess: false,
  FailureMessage: "",
  title: "",
  url: "",
};
export default defineComponent({
  name: "ContactFormSent",
  mounted() {
    remoteOriginatingSiteService = inject(
      Services.RemoteOriginatingSiteService
    ) as RemoteOriginatingSiteService;
    remoteOriginatingSiteService
      .retrieveUrlAndTitleFromNotDefault()
      .then((response: RemoteOriginatingSiteResponse) => {
        this.buttonInfo = response;
        this.buttonInfoLoading = false;
      });
  },
  data() {
    const auth0 = useAuth0();
    resources = inject(Services.Resources) as Resources;
    const isAuthenticated = auth0.isAuthenticated.value;
    return {
      buttonInfo,
      buttonInfoLoading: true,
      backToDetails: resources.links.backToDetails,
      contact24h: resources.messages.contact24h,
      thankyouForMessage: resources.messages.thankyouForMessage,
      isAuthenticated: isAuthenticated,
      goTo: resources.messages.goTo
    };
  },
  methods: {
    redirectToOriginatingSite(): void {
      window.location.href = this.buttonInfo.url as string;
    },
  },
  components: {
    LeftPanel,
    RadialSpinner,
    BlockableButton,
  },
});
