
import { Services } from "@/core/models/metadata/services.ts";
import { BlockNavigationService } from "@/core/services/blockNavigationService.ts";
import { ref, PropType, inject, defineComponent } from "vue";

export default defineComponent({
  props: {
    route: { required: true, type: String as PropType<String> },
    buttonText: { required: true, type: String as PropType<String> },
    underlined: { required: false, type: Boolean as PropType<Boolean>, default: false },
    idTag: { required: true, type: String as PropType<String>},
  },
  data() {
    return {
      blockNavigationService: ref(
        inject(Services.BlockNavigationService) as BlockNavigationService
      ),
    };
  },
  computed: {
    linksTextClasses(): any {
      return {
        "card-link-text": true,
        "card-link-text-blocked":
          this.blockNavigationService.isNavigationBlocked,
      };
    },
    linksTitles(): string {
      return this.blockNavigationService.isNavigationBlocked
        ? "Wait until current operation is completed"
        : "";
    },
    getRouterLinkClasses(): any {
      const { underlined, linksTextClasses } = this;
      const noUnderline = "noUnderline";
      const classesToApply = underlined
        ? [{ ...linksTextClasses }]
        : [{ ...linksTextClasses }, { noUnderline }];
      return classesToApply;
    },
  },
});
