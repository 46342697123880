
import { ErrorsService } from "@/core/services/errorsService.ts";
import { Services } from "@/core/models/metadata/services.ts";
import { PropType, defineComponent } from "vue";
import { inject } from "vue";
import { Error, ErrorType } from "@/core/models/metadata/error.ts";
import { ref } from "vue";

let errorsService!: ErrorsService;
export default defineComponent({
  name: "ErrorManager",
  props: {
    errorType: { required: false, type: String as PropType<ErrorType> },
    errorTypes: { required: false, type: [] as PropType<ErrorType[]> },
  },
  data() {
    errorsService = inject(Services.ErrorsService) as ErrorsService;
    return {
      errorsProperty: ref(errorsService.getAll()),
      //TO AVOID WARNING
      errorTypeProperty: this.errorType as ErrorType,
      errorTypesProperty: this.errorTypes as ErrorType[]
    };
  },
  computed: {
    errors(): Error[] {
      return this.errorsProperty;
    },
  },
  methods:{
    deleteError(error: Error){
      errorsService.delete(error);
    }
  }
});
