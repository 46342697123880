import { Auth0Plugin } from "@auth0/auth0-vue";
import { PublicContactFormQuery } from "../models/queries/contactFormQuery.ts";
import { ContactFormResponse } from "../models/responses/contactFormResponse.ts";
import { BackendServiceBase } from "./backendServiceBase.ts";
import Resources from "../models/resources.ts";
import { ErrorsService } from "./errorsService.ts";
import { BlockNavigationService } from "./blockNavigationService.ts";
import { ErrorType } from "../models/metadata/error.ts";

export class PublicContactFormService extends BackendServiceBase<PublicContactFormQuery, ContactFormResponse>{

    constructor(private readonly resources: Resources, blockNavigationService: BlockNavigationService, errorsService: ErrorsService, auth0Plugin: Auth0Plugin, backendEndpoint: string) {
        super(blockNavigationService, errorsService, auth0Plugin, backendEndpoint, ErrorType.ContactForm);
    }

    protected override defineErrorMessage(): string {
        return this.resources.errors.contactForm;
    }
    protected buildFetch(headers: any, query?: PublicContactFormQuery | undefined): Promise<Response> {
        return fetch(`${this.backendEndpoint}/SendContactUsFormLoggedOut`,{
            headers: headers,
            body: JSON.stringify(query),
            method: 'POST'
        });
    }

}