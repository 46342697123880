import { UpdateUserQuery } from "../models/queries/updateUserQuery.ts";
import { UpdateUserResponse } from "../models/responses/updateUserResponse.ts";
import { BackendServiceBase } from "./backendServiceBase.ts";
import { Auth0Plugin } from "@auth0/auth0-vue";
import { ErrorsService } from "./errorsService.ts";
import Resources from "../models/resources.ts";
import { BlockNavigationService } from "./blockNavigationService.ts";
import { ErrorType } from "../models/metadata/error.ts";
import { fieldType } from "../models/queries/fieldTypeEnum.ts";

export class UpdateUserService extends BackendServiceBase<UpdateUserQuery, UpdateUserResponse>{

    constructor(private readonly resources: Resources, blockNavigationService: BlockNavigationService, errorsService: ErrorsService, auth0Plugin: Auth0Plugin, backendEndpoint: string) {
        super(blockNavigationService, errorsService, auth0Plugin, backendEndpoint, ErrorType.UpdateField);
    }

    protected override defineErrorMessage(query: UpdateUserQuery): string {
        return query.Field == fieldType.email ? this.resources.errors.updateEmail :
            fieldType.password ? this.resources.errors.updatePassword : '';
    }
    protected buildFetch(headers: any, query?: UpdateUserQuery | undefined): Promise<Response> {
        return fetch(`${this.backendEndpoint}/update`, {
            headers: headers,
            method: 'PATCH',
            body: JSON.stringify(query)
        });
    }

}