import { reactive } from "vue";
import { Error, ErrorType } from "../models/metadata/error.ts";

export class ErrorsService {
    private errors: Error[] = reactive([]);
    public push(message: string, errorType: ErrorType): void {
        this.errors.push({
            id: crypto.randomUUID(),
            message: message,
            type: errorType
        });
    }
    public delete(error: Error): void {
        const index = this.errors.indexOf(error);
        this.errors.splice(index, 1);
    }
    public getAll(): Error[]{
        return this.errors;
    }
    public deleteAll(): void {
        while(this.errors.length){
            this.errors.splice(0, 1);
        }
    }
    public deleteByType(errorType: ErrorType): void{
        const currentErrorsForType = this.errors.filter(error => error.type === errorType);
        if(!currentErrorsForType.length)
            return;
        currentErrorsForType.forEach(error => this.errors.splice(this.errors.indexOf(error), 1));
    }
    
}
