import { UpdateUserNamesQuery } from "../models/queries/updateUserQuery.ts";
import Resources from "../models/resources.ts";
import { UpdateUserResponse } from "../models/responses/updateUserResponse.ts";
import { BackendServiceBase } from "./backendServiceBase.ts";
import { Auth0Plugin } from "@auth0/auth0-vue";
import { ErrorsService } from "./errorsService.ts";
import { BlockNavigationService } from "./blockNavigationService.ts";
import { ErrorType } from "../models/metadata/error.ts";

export class UpdateUserNamesService extends BackendServiceBase<UpdateUserNamesQuery, UpdateUserResponse>{

    constructor(private readonly resources: Resources, blockNavigationService: BlockNavigationService, errorsService: ErrorsService, auth0Plugin: Auth0Plugin, backendEndpoint: string) {
        super(blockNavigationService, errorsService, auth0Plugin, backendEndpoint, ErrorType.UpdateName);
    }
    protected override defineErrorMessage(): string {
        return this.resources.errors.updateName;
    }
    protected buildFetch(headers: any, query?: UpdateUserNamesQuery | undefined): Promise<Response> {
        return fetch(`${this.backendEndpoint}/updateUserNames`, {
            headers: headers,
            method: 'PATCH',
            body: JSON.stringify(query)
        });
    }

}