import { Auth0Plugin } from "@auth0/auth0-vue";
import { ErrorType } from "../models/metadata/error.ts";
import Resources from "../models/resources.ts";
import { BackendServiceBase } from "./backendServiceBase.ts";
import { BlockNavigationService } from "./blockNavigationService.ts";
import { ErrorsService } from "./errorsService.ts";
import { OtpLoginResponse } from "../models/responses/otpLoginResponse.ts";
import { OtpLoginQuery } from "../models/queries/otpLoginQuery.ts";

export class OtpLoginService extends BackendServiceBase<OtpLoginQuery, OtpLoginResponse>{

    constructor(private readonly resources: Resources, blockNavigationService: BlockNavigationService, errorsService: ErrorsService, auth0Plugin: Auth0Plugin, backendEndpoint: string) {
        super(blockNavigationService, errorsService, auth0Plugin, backendEndpoint, ErrorType.OtpLogin);
    }

    protected override defineErrorMessage(): string {
        return this.resources.errors.otpCode;
    }

    protected override async successErrorChallenge(result: OtpLoginResponse, query?: OtpLoginQuery): Promise<void>{
        if(!result.isSuccess && !result.hasAccountReachedItsLimit && !result.isWrongCode && !result.isCodeExpired && !result.rateLimitsExceeded){
            this.introduceErrorIfNotEmpty(query);
        }
    }

    protected buildFetch(headers: any, query: OtpLoginQuery): Promise<Response> {
        return fetch(`${this.backendEndpoint}/loginWithOtpCode?otpCode=${query.code}`, {
            headers: headers,
            method: 'GET'
        });
    }

}