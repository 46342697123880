
//This is the 'page' Auth0 public server will redirect after succesfull authentication flow
import { defineComponent } from "vue";
import { Auth0VueClient, useAuth0 } from "@auth0/auth0-vue";
import RadialSpinner from "./shared/radialSpinner.vue";
import RemoteOriginatingSiteService from "@/core/services/remoteOriginatingSiteService.ts";
import { Services } from "@/core/models/metadata/services.ts";
import { inject } from "vue";

let remoteOriginatingSiteService!: RemoteOriginatingSiteService;
let auth0: Auth0VueClient;
export default defineComponent({
  name: "AuthorizeNode",
  data() {
    return {
      notAuthenticated: !auth0.isAuthenticated.value && !auth0.isLoading.value,
      isLoading: auth0.isLoading.value,
      staticErrorMessage: "",
    };
  },
  beforeCreate() {
    auth0 = useAuth0();
    let innerRouter = this.$router;
    remoteOriginatingSiteService = inject(
      Services.RemoteOriginatingSiteService
    ) as RemoteOriginatingSiteService;
    remoteOriginatingSiteService.getIntendedAccessPath()
    .then((path) => {
      let intervalId = setInterval(() => {
        if (auth0.isAuthenticated.value) {
          path ||= "/userProfile";
          innerRouter.push(path);
          window.clearInterval(intervalId);
        }
      }, 100);
    });
  },
  methods: {
    login() {
      this.$router.push("");
    },
  },
  components: {
    RadialSpinner,
  },
});
