import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "errors-container" }
const _hoisted_2 = { class: "errors-list" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "error-delete" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors.filter((err) => err.type === _ctx.errorTypeProperty || _ctx.errorTypesProperty?.includes(err.type)), (error) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "error-item",
          key: error.id
        }, [
          _createElementVNode("div", {
            class: "error-message",
            innerHTML: error.message
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "error-delete-icon",
              onClick: ($event: any) => (_ctx.deleteError(error))
            }, null, 8, _hoisted_5)
          ])
        ]))
      }), 128))
    ])
  ]))
}