import { createApp, reactive } from "vue";
import App from "./App.vue";
import { Auth0Plugin, createAuth0 } from "@auth0/auth0-vue";
import { router, guardian } from "./core/routes.ts";
import { EmailVerificationService } from "./core/services/emailVerificationService.ts";
import Resources from "./core/models/resources.ts";
import { Services } from "./core/models/metadata/services.ts";
import { UpdateUserService } from "./core/services/updateUserService.ts";
import { StringHelper } from "./core/services/stringHelper.ts";
import { ValidationRulesService } from "./core/services/validationRulesService.ts";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import {
  VTextField,
  VForm,
  VField,
  VTextarea,
  VMessages,
  VSelect,
  VIcon,
} from "vuetify/components";
import { PublicContactFormService } from "./core/services/publicContactFormService.ts";
import { PrivateContactFormService } from "./core/services/privateContactFormService.ts";
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import { IsValidEndpointService } from "./core/services/isValidEndpointService.ts";
import RemoteOriginatingSiteService from "./core/services/remoteOriginatingSiteService.ts";
import { VueRecaptchaPlugin } from "vue-recaptcha/head";
import { UpdateUserNamesService } from "./core/services/updateUserNamesService.ts";

import { ErrorsService } from "./core/services/errorsService.ts";
import { BlockNavigationService } from "./core/services/blockNavigationService.ts";
import { VueGtmUseOptions, createGtm } from "@gtm-support/vue-gtm";
import { PasswordlessEmailCodeService } from "./core/services/passwordlessEmailCodeService.ts";
import { UpdateUserMetadataNewEmailService } from "./core/services/updateUserMetadataNewEmailService.ts";
import { StopOtpService } from "./core/services/stopOtpService.ts";
import { BackendServiceBase } from "./core/services/backendServiceBase.ts";
import { OtpLoginService } from "./core/services/otpLoginService.ts";
import { BaseResponse } from "./core/models/responses/baseResponse.ts";
import { UserExistsService } from "./core/services/userExistsService.ts";

const vuetify = createVuetify({
  components: {
    VTextField,
    VForm,
    VField,
    VTextarea,
    VMessages,
    VSelect,
    VIcon,
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
});

const auth0 = createAuth0({
  domain: process.env.VUE_APP_AUTH0_SERVER_URI,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  cacheLocation: "localstorage",
  useRefreshTokens: true,
  sessionCheckExpiryDays: 0,
  leeway: 5
});

const app = createApp(App).use(vuetify);
app.use(auth0);

app.use(
  createGtm({
    id: process.env.VUE_APP_GTM_ID, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: "2726c7f26c", // Will add `nonce` to the script tag
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    ignoredViews: ["homepage"], // Don't trigger events for specified router names (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    queryParams: {
      gtm_auth: process.env.VUE_APP_GTM_AUTH,
      gtm_preview: process.env.VUE_APP_GTM_PREVIEW,
      gtm_cookies_win: process.env.VUE_APP_GTM_COOKIES_WIN
    }
  } as VueGtmUseOptions)
);

app.use(VueRecaptchaPlugin, {
  v2SiteKey: process.env.VUE_APP_SITE_KEY_RECAPTCHA
});
injectServices().then(() => {
  app.mount("#app");
});

function injectServices(): Promise<void> {
  return new Promise((resolve, reject) => {
    const errorsService: ErrorsService = new ErrorsService();
    app.provide<ErrorsService>(Services.ErrorsService, errorsService);

    const stringHelper: StringHelper = new StringHelper();

    app.provide<StringHelper>(Services.StringHelper, stringHelper);

    const blockNavigationService: BlockNavigationService = reactive(
      new BlockNavigationService()
    );

    app.provide<BlockNavigationService>(
      Services.BlockNavigationService,
      blockNavigationService
    );

    const isValidEndpointService: IsValidEndpointService =
      new IsValidEndpointService(
        blockNavigationService,
        errorsService,
        auth0,
        process.env.VUE_APP_BACKEND_URI
      );
    app.provide<IsValidEndpointService>(
      Services.IsValidEndpointService,
      isValidEndpointService
    );
    const remoteOriginatingSiteService: RemoteOriginatingSiteService =
      new RemoteOriginatingSiteService(isValidEndpointService, router);
    app.provide<RemoteOriginatingSiteService>(
      Services.RemoteOriginatingSiteService,
      remoteOriginatingSiteService
    );

    router.beforeEach(guardian(auth0, errorsService, blockNavigationService));
    app.use(router);

    fetch("/config/resources.json")
      .then((res) => res.json())
      .then((resources: Resources) => {

        const passwordlessEmailCodeService = new PasswordlessEmailCodeService(auth0, errorsService, resources);
        app.provide<PasswordlessEmailCodeService>(Services.PasswordlessEmailCode, passwordlessEmailCodeService);

        function provideBackendService<TService extends BackendServiceBase<any, BaseResponse>>(
          descriptor: Services, 
          type: new (resources: Resources, blockNavigationService: BlockNavigationService, errorsService: ErrorsService, auth0Plugin: Auth0Plugin, backendEndpoint: string) => TService){
          const service: TService = new type(
            resources,
            blockNavigationService,
            errorsService,
            auth0,
            process.env.VUE_APP_BACKEND_URI) as TService;
          
          app.provide<TService>(descriptor, service);
        }        
        
        provideBackendService(Services.StopOtpCode, StopOtpService);
        provideBackendService(Services.LoginWithOtpCode, OtpLoginService);
        provideBackendService(Services.EmailVerification, EmailVerificationService);
        provideBackendService(Services.UpdateUser, UpdateUserService);
        provideBackendService(Services.UpdateUserNames, UpdateUserNamesService);
        provideBackendService(Services.UpdateUserMetadataNewEmail, UpdateUserMetadataNewEmailService);
        provideBackendService(Services.PublicContactForm, PublicContactFormService);
        provideBackendService(Services.PrivateContactForm, PrivateContactFormService);
        provideBackendService(Services.UserExists, UserExistsService);
        
        app.provide<Resources>(Services.Resources, resources);
        const validationRulesService: ValidationRulesService =
          new ValidationRulesService(resources, stringHelper);
        app.provide<ValidationRulesService>(
          Services.ValidationRules,
          validationRulesService
        );
        resolve();
      });
  });
}
