
import { Services } from "@/core/models/metadata/services.ts";
import Resources from "@/core/models/resources.ts";
import { defineComponent, inject } from "vue";
import { RemoteOriginatingSiteResponse } from "@/core/models/responses/remoteOriginatingSiteResponse.ts";
import { PropType } from "vue";
import { BlockNavigationService } from "@/core/services/blockNavigationService.ts";
import { ref } from "vue";
import RadialSpinner from "../shared/radialSpinner.vue";

let resources!: Resources;
export default defineComponent({
  name: "LeftPanel",
  props: {
    isAuthenticated: { required: true, type: Boolean as PropType<Boolean> },
    buttonInfoLoading: { required: true, type: Boolean as PropType<Boolean> },
    buttonInfo: {
      required: true,
      type: Object as PropType<RemoteOriginatingSiteResponse>,
    },
    showContactFormLink: {required: false, type: Boolean as PropType<Boolean>, default: true}
  },
  data() {
    resources = inject(Services.Resources) as Resources;

    return {
      manageKeyDetails: resources.titles.manageKeyDetails,
      goTo: resources.messages.goTo,
      needHelp: resources.messages.needHelp,
      problemContactForm: resources.messages.problemContactForm,
      contactCS: resources.messages.contactCS,
      blockNavigationService: ref(
        inject(Services.BlockNavigationService) as BlockNavigationService
      ),
    };
  },
  methods: {
    redirectToOriginatingSite(): void {
      if (this.blockNavigationService.isNavigationBlocked) return;
      window.location.href = this.buttonInfo.url as string;
    },
  },
  computed: {
    cardData() {
      const isNavigationBlocked =
        this.blockNavigationService.isNavigationBlocked;
      const userIconSrc = isNavigationBlocked
        ? require("@/assets/images/userDisabled.svg")
        : require("@/assets/images/user.svg");
      const arrowIconSrc = isNavigationBlocked
        ? require("@/assets/images/arrowNextDisabled.svg")
        : require("@/assets/images/arrowNext.svg");
      const plusIconSrc = isNavigationBlocked
        ? require("@/assets/images/arrowBackDisabled.svg")
        : require("@/assets/images/arrowBack.svg");

      return {
        userIcon: userIconSrc,
        arrowIcon: arrowIconSrc,
        plusIcon: plusIconSrc,
        titlesClasses: isNavigationBlocked ? "needHelpTitleDisabled" : "needHelpTitle",
        textClasses: isNavigationBlocked ? "descriptionTextDisabledNeedHelp" : "descriptionTextNeedHelp",
        linksTitles: isNavigationBlocked ? resources.messages.waitForOperationToComplete : "",
        linksTextClasses: {
          "card-link-text": true,
          "card-link-text-blocked": isNavigationBlocked,
        },
        borderClasses: isNavigationBlocked ? { enabledBorder: false, disabledBorder: true } : { enabledBorder: true, disabledBorder: false },
      };
    },
  },

  components: {
    RadialSpinner,
  },
});
